<template>
    <div>
        <b-card no-body>
            <b-tabs card>
                <b-tab disabled>
                    <template slot="title"><b>{{ $t('crud.settings._plural') }}</b></template>
                </b-tab>
                <b-tab v-for="(list, key) in lists" :key="key" :title="tab_titles[key]">
                    <settings-tab-component :configs="list"></settings-tab-component>
                </b-tab>
            </b-tabs>
        </b-card>
        <router-link :to="{name: 'extra.log'}" class="btn btn-sm btn-outline-secondary mb-2 mr-2"><i class="fa fa-cog"></i> Log</router-link>
        <router-link :to="{name: 'translations.index'}" class="btn btn-sm btn-outline-secondary mb-2 mr-2"><i class="fa fa-cog"></i> Translations</router-link>
    </div>
</template>

<script>
    import SettingsTabComponent from '@/views/settings/Tab';
    export default {
        mounted() {
            this.loading = true;
            this.$http.get('config').then((response) => {
                this.configs = response.data;
                this.loading = false;
            }).catch(() => { this.loading = false; });
        },
        components: {
            SettingsTabComponent
        },
        data() {
            return {
                configs: [],
                loading: false,
                tab_titles: {
                    system: 'Systeem',
                    text: 'Texten'
                }
            };
        },
        computed: {
            lists() {
                let obj = {
                    text: [],
                    system: []
                };
                this.configs.forEach((config) => {
                    let group = config.group || 'default';
                    if(obj[group]) {
                        obj[group].push(config);
                    } else {
                        obj[group]= [
                            config
                        ];
                    }
                });

                return obj;
            }
        }
    }
</script>
