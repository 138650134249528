<template>
    <div>
        <form @submit.prevent="update">
            <template v-for="(config, index) in configs">
                <template v-if="index && configs[index - 1].name.substr(0, configs[index - 1].name.indexOf('.'))  !== config.name.substr(0, config.name.indexOf('.'))">
                    <hr>
                </template>
                <b-form-group :label="config.title || config.name" horizontal :label-cols="4" breakpoint="md" :description="config.description">
                    <template v-if="config.input_type === 'text'">
                        <b-form-input v-model="config.value" :required="config.required"></b-form-input>
                    </template>
                    <template v-else-if="config.input_type === 'textarea'">
                        <b-form-textarea rows="6" v-model="config.value" :required="config.required"></b-form-textarea>
                    </template>
                    <template v-else-if="config.input_type === 'select'">
                        <b-form-select :options="config.select_list" v-model="config.value" :required="config.required">
                            <template slot="first">
                                <option :value="null">{{ $t('crud.choose_option') }}</option>
                            </template>
                        </b-form-select>
                    </template>
                    <template v-else-if="config.input_type === 'boolean' || config.input_type === 'checkbox'">
                        <b-form-checkbox value="1" unchecked-value="0" v-model="config.value"> Aan/uit </b-form-checkbox>
                    </template>
                </b-form-group>
            </template>
            <button v-if="loading" class="btn btn-primary"><loading></loading></button>
            <button v-else type="submit" class="btn btn-primary">{{ $t('crud.save') }}</button>
        </form>
    </div>
</template>

<script>
    export default {
        props: ['configs'],
        data() {
            return {
                loading: false
            };
        },
        methods: {
            update() {
                this.loading = true;
                this.$http.put('config', {
                    data: this.configs
                }).then((response) => {
                    this.$toastr.s(this.$t('notifications.saved', {object: this.$t('crud.settings._plural')}));
                    this.loading = false;
                }).catch(() => { this.loading = false; });
            }
        }
    }
</script>
